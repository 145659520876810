<template>
  <aheader/>
  <div class="hero">
    <div class="hero-body">
      <div class="level">
        <div class="level-item">
          <div class="title has-text-right ml-2">
            お問い合わせ
          </div>
        </div>
        <div class="level-item">
          <div class="content">
            <p class="has-text-left">
              住所： 大阪市旭区森小路2丁目9番1号
              <br>TEL： 06-6954-1515
              <br>E-mail： info@ogurayafuton.com
            </p>
            <p class="has-text-left">
              営業時間： 10:00～18:00
              <br>定休日： 火曜日
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button class="button is-danger" @click="pushPage('faq')">
      <span>
        よくある質問
      </span>
      <span class="icon is-small">
        <i class="fa-solid fa-up-right-from-square"></i>
      </span>
    </button>
  </div>
  <section class="section">
    <div class="title is-5">
      お問い合わせ内容
    </div>
    <div class="columns">
      <div class="column is-2 is-offset-2 has-text-left">
        お名前：
      </div>
      <div class="column is-6 is-offset-1">
        <div class="control">
          <input class="input" type="name" placeholder="お名前" v-model="name">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-2 is-offset-2 has-text-left">
        メールアドレス：
      </div>
      <div class="column is-6 is-offset-1">
        <div class="control">
          <input class="input" type="email" placeholder="メールアドレス" v-model="email">
          <br/>
          <input class="input" type="email2" placeholder="メールアドレス（ご確認用にもう一度ご入力ください）" v-model="email2">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-2 is-offset-2 has-text-left">
        お問い合わせ内容：
      </div>
      <div class="column is-6 is-offset-1">
        <div class="control">
          <textarea class="textarea" placeholder="お問い合わせ内容についてお書きください" rows="10" v-model="content"></textarea>
        </div>
      </div>
    </div>
    <div class="error-message notification is-danger is-light" v-show="errorMsg != ''" v-html="errorMsg"></div>
    <button ref="sendButton"
            class="button has-background-info-dark has-text-white-ter"
            :disabled="sending"
            @click.prevent="send">
      <span>
        送信
      </span>
      <span class="icon is-small">
        <i class="fa-solid fa-right-to-bracket"></i>
      </span>
    </button>
  </section>
  <afooter/>
</template>
<script>
  export default {
    data() {
      return {
        name: "",
        email: "",
        email2: "",
        subject: "",
        content: "",
        errorMsg: "",
        sending: false
      }
    },
    mounted() {
      let scriptEl = document.createElement('script');
      scriptEl.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render='+process.env.VUE_APP_RECAPTCHA_SITE_KEY);
      this.$refs.sendButton.appendChild(scriptEl);
      this.scrollTop();
    },
    methods: {
      send(){
        this.errorMsg = "";
        if (this.name == "") this.addErrMsg("お名前を入力してください。");
        if (this.email == "") {
          this.addErrMsg("メールアドレスを入力してください。");
        } else {
          if (this.email != this.email2) {
            this.addErrMsg("入力されたメールアドレスと確認用メールアドレスが一致しません。");
            this.email2 = "";
          }
        }
        if (this.content == "") this.addErrMsg("お問い合わせ内容を入力してください。");

        if (this.errorMsg == "") {
          this.sending = true;
          grecaptcha.ready(function() {
            grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                this.verify2send(token);
            }.bind(this));
          }.bind(this));
        }
      },
      async verify2send(token) {
        //console.log(token)
        const response = await this.$axios.post(process.env.VUE_APP_FUNCTIONS_URL_VERIFY2INQUIRY, {
          token: token,
          name: this.name,
          email: this.email,
          content: this.content
        });
        if (response.data.status == 'OK') {
          this.$store.commit('setIquryID', response.data.id);
          this.$router.push('inquiry_complete');
        } else {
          this.$store.commit('setIquryID', "");
          this.addErrMsg("エラーが発生しました。");
          this.sending = false;
        }
      },
      addErrMsg(msg) {
        if (this.errorMsg != "") this.errorMsg += "<br/>";
        this.errorMsg = this.errorMsg + msg;
      },
      scrollTop() {
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        })
      },
      pushPage(page) {
        this.$router.push(page)
      }
    }
  }
</script>
